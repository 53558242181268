import React, { useEffect } from 'react';
import { useMe } from './useAuth';
import { useSelectedAccount } from './useAccounts';

const useIsStatusU = () => {
  const [isStatusU, setIsStatusU] = React.useState(false);
  const { data } = useMe();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  useEffect(() => {
    if (
      data?.principals[0]?.accounts.some(
        (account: any) =>
          account.status === 'U' &&
          selectedAccount?.isJuristicPerson === account.isJuristicPerson &&
          selectedAccount?.id === account.id,
      )
    ) {
      setIsStatusU(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return isStatusU;
};

export default useIsStatusU;
