import moment, { Moment } from 'moment';
import { AUDIT_URL } from 'constants/Jaws';
import { post } from './fetch';

export function formatDate(date: Moment | Date) {
  if (date instanceof Date) return moment(date).format('YYYY-MM-DD');
  return date.format('YYYY-MM-DD');
}

const periodDefaults = {
  start: formatDate(
    moment()
      .subtract(1, 'week')
      .startOf('day'),
  ),
  stop: formatDate(moment()),
};

const AuditUtil = {
  getDefault(period: keyof typeof periodDefaults) {
    return periodDefaults[period];
  },
};

export function audit(ownerId: string, accountId: string, data: any) {
  return post(
    `${AUDIT_URL}/${ownerId}?accountId=${accountId}`,
    JSON.stringify({ data }),
  );
}

export const KEYS = [
  'select',
  'alert_triggered',
  'alert_send_sms',
  'alert_send_sms_ok',
  'alert_send_sms_failed',
  'alert_send_email',
  'alert_send_email_ok',
  'alert_send_email_failed',
  'alert_add',
  'alert_delete',
  'alert_activate',
  'alert_deactivate',
  'alert_edit',
  'user_details_mobile_changed',
  'user_details_mobile_confirmed',
  'user_details_email_changed',
  'adminUserCreated',
  'adminUserModified',
  'admin_useradmin_user_delete',
  'admin_editor_file_new',
  'admin_editor_file_update',
  'admin_editor_file_delete',
  'admin_editor_sniplet_update',
  'portal_fictive_portfolio_add',
  'portal_fictive_portfolio_delete',
  'loginAdmin',
  'loginAdminUnique',
  'loginFailed',
  'passwordChanged',
  'passwordReset',
  'insertOrder',
  'executeInsertOrder',
  'modifyOrder',
  'executeModifyOrder',
  'executeDeleteOrder',
  'insertOrderFailed',
  'executeInsertOrderFailed',
  'modifyOrderFailed',
  'executeModifyOrderFailed',
  'executeDeleteOrderFailed',
  'transferPositions',
  'portal_login_support',
  'portal_login_advisor',
  'loginUser',
  'competencyAssessmentCompleted',
  'competencyAssessmentCompletedDiscouraged',
  'loginUserUnique',
  'portal_password_change',
  'portal_forgot_pass_failed',
  'portal_watchlist_add',
  'portal_watchlist_add_failed',
  'portal_watchlist_remove',
  'portal_watchlist_remove_failed',
  'portal_user_details_email_changed',
  'portal_user_details_mobile_changed',
  'portal_user_details_mobile_confirmed',
  'hTestCreated',
  'hTestCreateFailed',
  'hTestModified',
  'hTestModifiyFailed',
  'launderingTestCreated',
  'launderingTestCreateFailed',
  'launderingTestModified',
  'launderingTestModifyFailed',
  'termsAcceptanceCreated',
  'termsAcceptanceCreateFailed',
  'termsAcceptanceModified',
  'termsAcceptanceModifyFailed',
  'expediencyWarningDisplayed',
  'expediencyWarningHidden',
  'customerCreated',
  'customerCreatedFailed',
  'marginApplicationCreated',
  'marginApplicationCreateFailed',
  'marginApplicationUpdated',
  'marginApplicationUpdateFailed',
  'marginApplicationDeleted',
  'marginApplicationDeleteFailed',
  'activeOrderInInstrumentWarningDisplayed',
  'openVolumeWarningDisplayed',
  'brokerageFeeLimitExceeded',
] as const;

export const GROUPS = {
  all: { keys: [] },
  ask: {
    keys: ['transferPositions'],
  },
  adminUserAdmin: {
    keys: [
      'adminUserCreated',
      'adminUserModified',
      'admin_useradmin_user_delete',
    ],
  },
  adminEditor: {
    keys: [
      'admin_editor_file_new',
      'admin_editor_file_update',
      'admin_editor_file_delete',
      'admin_editor_sniplet_update',
    ],
  },
  expediencyWarning: {
    keys: ['expediencyWarningDisplayed', 'expediencyWarningHidden'],
  },
  termsAcceptance: {
    keys: [
      'termsAcceptanceCreated',
      'termsAcceptanceCreateFailed',
      'termsAcceptanceModified',
      'termsAcceptanceModifyFailed',
    ],
  },
  portalTrading: {
    keys: [
      'insertOrder',
      'executeInsertOrder',
      'modifyOrder',
      'executeModifyOrder',
      'executeDeleteOrder',
      'insertOrderFailed',
      'executeInsertOrderFailed',
      'modifyOrderFailed',
      'executeModifyOrderFailed',
      'executeDeleteOrderFailed',
      'activeOrderInInstrumentWarningDisplayed',
      'openVolumeWarningDisplayed',
    ],
  },

  launderingTest: {
    keys: [
      'launderingTestCreated',
      'launderingTestCreateFailed',
      'launderingTestModified',
      'launderingTestModifyFailed',
    ],
  },

  hTest: {
    keys: [
      'hTestCreated',
      'hTestCreateFailed',
      'hTestModified',
      'hTestModifiyFailed',
    ],
  },

  customerCreation: {
    keys: ['customerCreated', 'customerCreatedFailed'],
  },

  portalUserDetails: {
    keys: [
      'portal_user_details_email_changed',
      'portal_user_details_mobile_changed',
      'portal_user_details_mobile_confirmed',
    ],
  },

  adminLogin: {
    keys: ['loginAdmin', 'loginFailed', 'passwordChanged', 'passwordReset'],
  },
  portalLogin: {
    keys: ['loginUser'],
  },

  portalWatchlist: {
    keys: [
      'portal_watchlist_add',
      // 'portal_watchlist_add_failed', not used in reports
      'portal_watchlist_remove',
      // 'portal_watchlist_remove_failed', not used in reports
    ],
  },

  marginApplication: {
    keys: [
      'marginApplicationCreated',
      'marginApplicationCreateFailed',
      'marginApplicationUpdated',
      'marginApplicationUpdateFailed',
      'marginApplicationDeleted',
      'marginApplicationDeleteFailed',
    ],
  },
  alertServer: {
    keys: [
      'alert_triggered',
      'alert_send_sms_ok',
      'alert_send_sms_failed',
      'alert_send_email_ok',
      'alert_send_email_failed',
    ],
  },
  portalAlert: {
    keys: [
      'alert_add',
      'alert_edit',
      'alert_delete',
      'alert_activate',
      'alert_deactivate',
    ],
  },
  brokerageLimit: {
    keys: ['brokerageFeeLimitExceeded', 'brokerageFeeLimitUpdated'],
  },
};

// Keys
export const NAMES: any = {
  all: 'Alle',
  select: 'Velg...',
  // Keys
  /* eslint-disable camelcase, max-len */
  alert_triggered: 'Varsling utløst',
  alert_send_sms: 'SMS varsling utgående',
  alert_send_sms_ok: 'SMS varsling sendt',
  alert_send_sms_failed: 'SMS varsling feilet',
  alert_send_email: 'E-post varsling utgående',
  alert_send_email_ok: 'E-post varsling sendt',
  alert_send_email_failed: 'E-post varsling feilet',
  alert_add: 'Ny varsling',
  alert_delete: 'Slett varsling',
  alert_activate: 'Aktiver varsling',
  alert_deactivate: 'Deaktiver varsling',
  alert_edit: 'Endre varsling',
  user_details_mobile_changed: 'Endre mobiltelefonnummer',
  user_details_mobile_confirmed: 'Bekrefte nytt mobiltelefonnummer',
  user_details_email_changed: 'Endre e-postadresse',
  adminUserCreated: 'Ny bruker',
  adminUserModified: 'Endre bruker',
  admin_useradmin_user_delete: 'Slett bruker',
  admin_editor_file_new: 'Ny fil',
  admin_editor_file_update: 'Endre fil',
  admin_editor_file_delete: 'Slett fil',
  admin_editor_sniplet_update: 'Endre sniplet',
  customerCreated: 'Kunde opprettet',
  customerCreatedFailed: 'Kunde opprettet feilet',
  portal_fictive_portfolio_add: 'Ny fiktiv portefølje',
  portal_fictive_portfolio_delete: 'Slett fiktiv portefølje',
  loginUser: 'Login kunde',
  loginUserFailed: 'Login kunde feilet',
  loginAdmin: 'Login admin',
  loginAdminUnique: 'Login admin unik',
  loginFailed: 'Login admin feilet',
  passwordChanged: 'Bytt passord admin',
  passwordReset: 'Reset passord admin',
  insertOrder: 'Opprett ordre',
  executeInsertOrder: 'Opprett ordre bekreftet',
  modifyOrder: 'Endring av ordre',
  executeModifyOrder: 'Endring av ordre bekreftet',
  executeDeleteOrder: 'Ordre slettet',
  insertOrderFailed: 'Opprett av ordre feilet',
  executeInsertOrderFailed: 'Opprett ordre bekreftelse feilet',
  modifyOrderFailed: 'Endring av ordre feilet',
  executeModifyOrderFailed: 'Endring av ordre bekreftelse feilet',
  executeDeleteOrderFailed: 'Sletting av ordre feilet',
  transferPositions: 'Overføring til ASK',
  portal_login_support: 'Login support',
  portal_login_advisor: 'Login rådgiver',
  portal_login: 'Login kunde',
  loginUserUnique: 'Login kunde unik',
  portal_password_change: 'Bytt passord kunde',
  portal_forgot_pass_failed: 'Glemt passord feilet',
  portal_watchlist_add: 'Legg til i Min Liste',
  portal_watchlist_add_failed: 'Legg til i Min Liste feilet',
  portal_watchlist_remove: 'Fjern fra Min Liste',
  portal_watchlist_remove_failed: 'Fjern fra Min Liste feilet',
  portal_user_details_email_changed: 'Endret epostadresse',
  portal_user_details_mobile_changed: 'Endret mobilnummer',
  portal_user_details_mobile_confirmed: 'Bekreftet mobilnummer',
  hTestCreated: 'H-test registrert',
  hTestCreateFailed: 'Feil ved registering av H-test',
  hTestModified: 'H-test oppdatert',
  hTestModifiyFailed: 'Feil ved oppdatering av H-test',
  launderingTestCreated: 'Hvitvaskingskontroll registrert',
  launderingTestCreateFailed: 'Feil ved registrering av hvitvaskingskontroll',
  launderingTestModified: 'Hvitvaskingskontroll oppdatert',
  launderingTestModifyFailed: 'Feil ved oppdatering av Hvitvaskingskontroll',
  termsAcceptanceCreated: 'Godkjenning av vilkår registrert',
  termsAcceptanceCreateFailed: 'Feil ved registrering av godkjenning av vilkår',
  termsAcceptanceModified: 'Godkjenning av vilkår oppdatert',
  termsAcceptanceModifyFailed: 'Feil ved oppdatering av godkjenning av vilkår',
  expediencyWarningDisplayed: 'Frarådning fremvist',
  expediencyWarningHidden: 'Frarådning skjult',
  marginApplicationCreated: 'Søknad om margin opprettet',
  marginApplicationCreateFailed: 'Feil ved opprettelse av søknad om margin',
  marginApplicationUpdated: 'Søknad om margin endret',
  marginApplicationUpdateFailed: 'Feil ved endring av søknad om margin',
  marginApplicationDeleted: 'Søknad om margin slettet',
  marginApplicationDeleteFailed: 'Feil ved sletting av søknad om margin',
  competencyAssessmentCompletedDiscouraged: 'Knowledge Test – Discouraged',
  competencyAssessmentCompleted: 'Knowledge Test – OK',
  /* eslint-enable */
  // groups
  ask: 'ASK',
  customerCreation: 'Kundeopprettelse',
  adminUserAdmin: 'Brukeradmin',
  adminEditor: 'Editor',
  adminLogin: 'Login admin',
  portalLogin: 'Login kunde',
  portalAlert: 'Varslinger',
  portalUserDetails: 'Kundeinformasjon',
  alertServer: 'Utsendelse av varsling',
  portalWatchlist: 'Min Liste',
  portalTrading: 'Handel',
  hTest: 'H-Test',
  launderingTest: 'Hvitvaskingskontroll',
  termsAcceptance: 'Godkjenning av vilkår',
  expediencyWarning: 'Frarådning',
  marginApplication: 'Søknad om margin',
  activeOrderInInstrumentWarningDisplayed:
    'Varsel på aktiv ordre på samme ticker',
  openVolumeWarningDisplayed: 'Varsel om endring av åpent volum',
  brokerageFeeLimitUpdated: 'Kurtasje grense oppdatert',
  brokerageFeeLimitExceeded: 'Kurtasjevarsel vist',
  brokerageLimit: 'Kurtasje grense',
};

export default AuditUtil;
