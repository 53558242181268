import { FetchError, get, post, put } from 'utils/fetch';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useUser from './useUser';
import { CUSTOMER_URL } from 'constants/Jaws';
import { useInstrumentMetaData } from './useInstrument';

export const getLongName = (name: AssessmentTest['name']) => {
  const longNameMap: Record<string, string> = {
    etn_test: 'ETN-er',
    merk_test: 'Merkur Market',
    rights_test: 'Tegningsretter',
  };

  return longNameMap[name] || name;
};

export type Question = {
  question: string;
  alternatives: string[];
  url: string;
  urlLabel: string;
  correct: number[];
  ans?: any;
};

export type Data = Record<string, Question>;

export type AssessmentTest = {
  id: number;
  name: string;
  data: Data;
};

export type StoredTestResult = {
  id: number;
  created: Date;
  modified: Date;
  result: string;
  test: {
    id: number;
    name: string;
  };
  warningAcknowledged: boolean;
};

export const fetchTestResults = async (userId: string) => {
  const response = await get(
    `${CUSTOMER_URL}/${userId}/competency-assessment-test-result`,
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Henting av kompetansetester feilet',
      response,
    });
  const data = await response.json();
  return data as StoredTestResult[];
};

export const useAssessmentTestResults = () => {
  const {
    data: { userId = '' },
  } = useUser();
  return useQuery(
    ['assessmentTestResults', userId],
    () => fetchTestResults(userId),
    { enabled: !!userId },
  );
};

type TestResult = {
  result: 'COMPLETED' | 'COMPLETED_DISCOURAGED';
  warningAcknowledged?: boolean;
  competencyAssessmentTestId: number;
};
export const updateAssessmentTestResult = async (
  userId: string,
  testResult: TestResult,
) => {
  const payload = JSON.stringify({
    result: testResult.result,
    warningAcknowledged: testResult.warningAcknowledged,
  });
  const response = await put(
    `${CUSTOMER_URL}/${userId}/competency-assessment-test-result/${testResult.competencyAssessmentTestId}`,
    payload,
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Lagring av kompetansetest feilet',
      response,
    });
  return response;
};

export const useUpdateAssessmentTestResult = () => {
  const {
    data: { userId = '' },
  } = useUser();
  return useMutation((testResult: TestResult) =>
    updateAssessmentTestResult(userId, testResult),
  );
};

export const addAssessmentTestResult = async (
  userId: string,
  testResult: TestResult,
) => {
  const previousTestResults = await fetchTestResults(userId);
  const previouslyAnswered = previousTestResults.find(
    result => result.test.id === testResult.competencyAssessmentTestId,
  );

  if (previouslyAnswered) {
    return updateAssessmentTestResult(userId, {
      ...testResult,
      competencyAssessmentTestId: previouslyAnswered.id,
    });
  }

  const payload = JSON.stringify({
    competencyAssessmentTestId: testResult.competencyAssessmentTestId,
    result: testResult.result,
    warningAcknowledged: testResult.warningAcknowledged,
  });
  const response = await post(
    `${CUSTOMER_URL}/${userId}/competency-assessment-test-result`,
    payload,
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Lagring av kompetansetest feilet',
      response,
    });
  return response;
};

export const useAddAssessmentTestResult = () => {
  const queryClient = useQueryClient();
  const {
    data: { userId = '' },
  } = useUser();
  return useMutation(
    (testResult: TestResult) => addAssessmentTestResult(userId, testResult),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assessmentTestResults');
      },
    },
  );
};

export const assessmentTestMap = {
  ETN: 1,
  MERK_EXCHANGE_NOTED: 2,
  RIGHTS_EXCHANGE_NOTED: 3,
};

export const useRequiredAssessmentTest = (itemSector: string) => {
  const { data: testResults } = useAssessmentTestResults();
  const { data: instrumentMetaData } = useInstrumentMetaData(itemSector);

  const requiredTest = Object.keys(assessmentTestMap).find(instrumentGroup =>
    instrumentMetaData?.groups.includes(instrumentGroup),
  ) as keyof typeof assessmentTestMap | undefined;

  if (!requiredTest) return;
  const requiredTestId = assessmentTestMap[requiredTest];
  const completedTest = testResults?.find(
    result => result.test.id === requiredTestId,
  );

  if (!completedTest) return requiredTestId;

  if (
    completedTest.result === 'COMPLETED_DISCOURAGED' &&
    !completedTest.warningAcknowledged
  ) {
    return requiredTestId;
  }

  if (
    requiredTest === 'MERK_EXCHANGE_NOTED' &&
    instrumentMetaData?.type === 'RIGHTS'
  ) {
    const rightsTest = testResults?.find(
      result => result.test.id === assessmentTestMap.RIGHTS_EXCHANGE_NOTED,
    );
    if (!rightsTest) return assessmentTestMap.RIGHTS_EXCHANGE_NOTED;
    if (
      rightsTest.result === 'COMPLETED_DISCOURAGED' &&
      !rightsTest.warningAcknowledged
    ) {
      return assessmentTestMap.RIGHTS_EXCHANGE_NOTED;
    }
  }

  return;
};
