import styled from '@emotion/styled';
import Wizard, { WizardPage as WPage } from './Wizard';
import { useUser } from 'utils/hooks/useUser';

/*
import {
  getProducts,
  orderProduct,
  removeProduct,
  mapIdToProduct,
  SENT_TO_RESERVATION,
} from 'utils/products';
*/

import CustomerInformation from './CustomerInformation';
import CustomerEducation from './CustomerEducation';
import Knowledge from './Knowledge';
import TermsAndDisclaimer from './TermsAndDisclaimer';
import Laundering from './Laundering';
import Education from './Education';
import PoliticallyExposed from './PoliticallyExposed';
import Profession from './Profession';
import Summary from './Summary';
import { useMemo } from 'react';
import { Heading } from '@oms/ui-heading';
import { useParams } from 'react-router-dom';
import { FormValues } from './types';
import {
  useGetPersonalInfo,
  selAccountData,
} from 'utils/hooks/useExpediencyTest';
import AdditionalCustomerInformation from './AdditionalCustomerInformation';
import { Account } from 'types/auth';

const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;

const Space = styled.div`
  padding: 4px 4px;
`;

const WizardPage = styled(WPage)`
  padding: 10px 5px;
`;

type Props = {
  initialData: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<any> | void;
  isPDFRender: boolean;
  isCorpoAccount: boolean;
  selectedAccount: Account;
};

/** Previously known as H-test */
export const ExpediencyTestCorpo = ({
  initialData,
  onSubmit,
  isPDFRender,
  isCorpoAccount,
  selectedAccount,
}: Props) => {
  const { step: stepParam } = useParams<{ step?: string }>();
  const step = Number(stepParam);

  const {
    data: {
      name = '',
      companyNumber = '',
      email = '',
      postalCode = '',
      address = '',
      bankAccount = '',
      vpsAccount = '',
      phoneNumber = '',
    } = {},
  } = useGetPersonalInfo(selectedAccount, isCorpoAccount);

  const {
    data: { userId = '', accounts },
  } = useUser();

  const accountData = selAccountData(selectedAccount?.id, accounts);

  const initialValues = useMemo(
    () => ({
      userId: userId,
      personalia: {
        name: accountData?.fullName || name,
        phoneNumber: accountData?.phoneNumber || phoneNumber,
        companyNumber: companyNumber,
        email: accountData?.email || email,
        address: accountData?.address1 || address,
        zipCode: accountData?.postalCode || postalCode,
        bankAccountNo: accountData?.bankAccountNo || bankAccount,
        vpsAccountNo: accountData?.vpsAccountNo || vpsAccount,
        ...initialData.personalia,
      },
      additionalInformation: {
        updates: {
          productsAndServices: true,
          stockExchangeListings: true,
          marketAndStocks: true,
          invitations: true,
          ...initialData.additionalInformation?.updates,
        },
        ...initialData.additionalInformation,
      },
      additionalPersonalInformation: {
        ...initialData.additionalPersonalInformation,
      },
      ...initialData,
    }),
    [
      userId,
      name,
      email,
      postalCode,
      address,
      bankAccount,
      vpsAccount,
      phoneNumber,
      initialData,
      companyNumber,
      accountData,
    ],
  );
  return (
    <Wizard
      onSubmit={onSubmit}
      initialValues={initialValues as any}
      step={step}
      isPDFRender={isPDFRender}
      isCorpoAccount={isCorpoAccount}
    >
      <WizardPage>
        <Heading as="h2" variant="heading4">
          Bedriftskonto
        </Heading>
        <CustomerInformation />
        <AdditionalCustomerInformation />
      </WizardPage>
      <WizardPage>
        <Heading as="h2" variant="heading4">
          Yrke, utdanning og erfaring
        </Heading>
        <CustomerEducation />
        <Divider />
        <Education />
        <Space />
        <Divider />
        <Profession />
        <Divider />
      </WizardPage>

      <WizardPage>
        <Knowledge />
      </WizardPage>

      <WizardPage>
        <Laundering />
      </WizardPage>

      <WizardPage>
        <Heading as="h2" variant="heading4">
          Politisk eksponert person
        </Heading>
        <PoliticallyExposed />
      </WizardPage>

      <WizardPage>
        <TermsAndDisclaimer />
      </WizardPage>

      <WizardPage>
        <Summary />
      </WizardPage>
    </Wizard>
  );
};

export default ExpediencyTestCorpo;
