import { useAssessmentTestByID } from 'utils/hooks/admin/useAssessmentTests';
import { Form } from 'react-final-form';
import { Stack } from '@oms/ui-stack';
import { Field, Fieldset, RenderField } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Button } from '@oms/ui-button';
import { FORM_ERROR } from 'final-form';
import { CardBox } from 'components/Card';
import { useAddAssessmentTestResult } from 'utils/hooks/useAssessmentTestResults';
import { useState } from 'react';
import { Notification } from '@oms/ui-notification';
import { Checkbox } from '@oms/ui-checkbox';

export type AssessmentTestFormProps = {
  id: number;
  onClose?: () => void;
};

const getTestResult = (
  questions: Record<string, { correct: number[] }>,
  answers: any,
) => {
  if (!questions) throw new Error('Fant ikke spørsmål');

  const allCorrect = Object.entries(questions).every(
    ([questionKey, question]) => {
      const correctArr = question.correct;
      const ans = parseInt(answers[questionKey]);

      return correctArr.includes(ans);
    },
  );
  if (!allCorrect) return 'COMPLETED_DISCOURAGED';
  return 'COMPLETED';
};

// TODO: Loadingstate and Errorstate
export const AssessmentTestForm = ({
  id,
  onClose,
}: AssessmentTestFormProps) => {
  const { data: test } = useAssessmentTestByID(id);
  const [showWarning, setShowWarning] = useState(false);
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);
  const { mutateAsync: addTestResult } = useAddAssessmentTestResult();
  if (!test) return null;

  const handleSubmit = (values: any) => {
    const testResult = getTestResult(test.data, values);
    if (testResult === 'COMPLETED_DISCOURAGED' && !warningAcknowledged) {
      setShowWarning(true);
      return { [FORM_ERROR]: '' };
    }

    const result = addTestResult({
      competencyAssessmentTestId: id,
      result: testResult,
      warningAcknowledged,
    });
    setShowWarning(false);
    setWarningAcknowledged(false);
    return result;
  };

  const handleValidation = (values: any) => {
    if (!test) return { [FORM_ERROR]: 'Mangler spørsmål, kan ikke validere' };
    const questionKeys = Object.keys(test.data);
    const allAnswered = questionKeys.every(key => key in values);
    if (!allAnswered) return { [FORM_ERROR]: 'Alle spørsmål må besvares' };
    return;
  };

  return (
    <Form onSubmit={handleSubmit} validate={handleValidation}>
      {({ handleSubmit, hasValidationErrors, submitting }) => {
        return (
          <Stack as="form" gap={4} onSubmit={handleSubmit}>
            {Object.entries(test?.data).map(([questionKey, question]) => (
              <CardBox>
                <Fieldset
                  as={RadioGroup}
                  name={questionKey}
                  legend={question.question}
                >
                  {question.alternatives.map((alternative, index) => (
                    <Field
                      as={Radio}
                      label={alternative}
                      // Note: Will get back string even if we pass number,
                      // which broke checked-functionality. Solution: pass in string.
                      value={index.toString()}
                    />
                  ))}
                </Fieldset>
              </CardBox>
            ))}

            {showWarning && (
              <Notification title="Advarsel" status="warning">
                <p>
                  På bakgrunn av de opplysningene vi har om ditt kunnskaps- og
                  erfaringsnivå, vurderer vi dette finansielle instrumentet å
                  ikke være hensiktsmessig for deg. Det er viktig at du som
                  kunde har kunnskap og erfaring til å forstå hvilken risiko du
                  påtar deg ved handel i dette instrumentet. Vi vil derfor
                  fraråde deg å handle dette finansielle instrumentet.
                </p>
                <p>
                  Dersom du likevel ønsker det, kan du gjennomføre handel via
                  vår netthandelsløsning.
                </p>
                <RenderField
                  as={Checkbox}
                  label="Gå videre likevel"
                  name="warningAcknowledged"
                  checked={warningAcknowledged}
                  onChange={(e: any) =>
                    setWarningAcknowledged(e.currentTarget.checked)
                  }
                />
              </Notification>
            )}

            <Stack orientation="horizontal">
              <Button
                variant="secondary"
                title="Avbryt"
                type="button"
                onClick={() => onClose?.()}
              >
                Avbryt
              </Button>
              <Button
                title="Lagre"
                type="submit"
                disabled={
                  hasValidationErrors || (showWarning && !warningAcknowledged)
                }
                isPending={submitting}
              >
                Lagre
              </Button>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};

export default AssessmentTestForm;
